import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on, addClass, removeClass} from '@elements/dom-utils';

export function init() {
    onFind('.js-form-control-submit', function (baseElement) {
        let button = findIn('.js-form-control-submit__button', baseElement);
        let input = findIn('.js-form-control-submit__input', baseElement);

        on('input', (e) => {
            if(input.value !== '') {
                addClass('is-active', button);
            }else{
                removeClass('is-active', button);
            }
        }, input);
    });
}