"use strict";

import { on, findAllIn, findIn, trigger, closest, hasClass, addClass, removeClass} from '@elements/dom-utils';
import {onFind} from '@elements/init-modules-in-scope';
import * as formValidation from '@elements/form-validation';

export function init() {
    onFind('.js-custom-select', select => {
        let input = findIn('.js-custom-select__input', select);
        let items  = findAllIn('.js-custom-select__item', select);
        let valueLabel = findIn('.js-custom-select__value-label', select);
        let button = findIn('.js-custom-select__button', select);
        let form = select.closest('form');
        let value = input.value;

        items.forEach(function (item) {
            on('click', (e) => {
                input.value = e.target.getAttribute('data-value');
                trigger('change', input);
                valueLabel.innerHTML = e.target.innerHTML;
            }, item);

            if (item.getAttribute('data-value') === value) {
                valueLabel.innerHTML = item.innerHTML;
            }
        });

        //wenn formvalidation event kommt, dann prüfen ob is-invalid am input ist und dann die klasse am button setzen
        if(form){
            if(hasClass('js-form-validation', form)){
                let formInstance;

                formValidation.getApi(form).then((formApi) => {
                    formInstance = formApi.getFormInstance();
                    formInstance.on('core.element.validated', (e) => {
                        if(e.field === input.name && e.valid === false){
                            addClass('is-invalid', button);
                        }else if(e.field === input.name && e.valid === true){
                            removeClass('is-invalid', button);
                            addClass('is-valid', button);
                        }
                    });

                    on('change', (e) => {
                        if(input.getAttribute('required')){
                            formInstance.validateField(input.name);
                        }
                    }, input);
                });
            }
        }
    });
}