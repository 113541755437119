import {onFind} from "@elements/init-modules-in-scope";
import {closest, addClass, findAllIn, removeClass} from '@elements/dom-utils';
import {getPrefixedDataSet} from "@elements/data-set-utils";

export function init () {
    onFind('.js-nav-active-state', function (baseElement) {
        // current Link
        let url = window.location.pathname;
        let links = findAllIn('a.js-nav-active-state__link[href="'+url+'"]', baseElement);
        if(window._config.navActiveStateId){
            let idLinks = findAllIn('a.js-nav-active-state__link[data-nav-active-state-id="' + window._config.navActiveStateId + '"]', baseElement);
            if(idLinks.length > 1){
                if(links.length > 1){
                    //always use last found link because of overview links in main nav
                    checkLinks(links[links.length - 1]);
                }else{
                    checkLinks(links[0]); 
                }
            }else if(idLinks.length === 1){
                let dataset = getPrefixedDataSet('nav-active-state', idLinks[0]);
                if(parseInt(dataset.id) === window._config.navActiveStateId){
                    checkLinks(idLinks[0]);
                }
            }
        }else{
            if(links.length > 1){
                //always use last found link because of overview links in main nav
                checkLinks(links[links.length - 1]);
            }else{
                checkLinks(links[0]);
            }
        }
    });
}

function checkLinks(link){
    addClass('is-active', link);
    let level0 = closest('.js-nav__level-0', link);
    let level1 = closest('.js-nav__level-1', link);
    if(level1){
        removeClass('is-active', level0);
        addClass('is-active', level1);
        addClass('is-active', level1.previousElementSibling);

        let level2 = closest('.js-nav__level-2', link);
        if(level2){
            addClass('is-active', level2);
            addClass('is-active', level2.previousElementSibling);
        }
    }
}