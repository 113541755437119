class DateInstance {
    constructor(
        date,
        price,
        isSelected = false,
        isDisabled = false,
        isBestPrice = false,
        isStartDate = false,
        isMaxSelection = false,
        isMinSelection = false,
        isLastAvailableDate = false,
        isNotAllowedAsStartDate = false
    ) {
        this.date = date;
        this.price = price;
        this.isSelected = isSelected;
        this.isDisabled = isDisabled;
        this.isBestPrice = isBestPrice;
        this.isStartDate = isStartDate;
        this.isMaxSelection = isMaxSelection;
        this.isMinSelection = isMinSelection;
        this.isLastAvailableDate = isLastAvailableDate;
        this.isNotAllowedAsStartDate = isNotAllowedAsStartDate;
        this.showTooltip = false;
    }
}

export { DateInstance }
