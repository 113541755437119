import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, addClass, findIn, closest, on} from '@elements/dom-utils';

export function init () {
    onFind('.js-show-more', function (element) {
        if (element.getAttribute('data-show-more-on-xs')) {
            if (window.matchMedia("(max-width: 767px)").matches) {
                showMore(element);
            }else{
                showMore(element);
            }
        }

    });
}

function showMore(element){
    let showMoreContainer = closest('.js-show-more-container', element),
        showMoreBtn = findIn('.js-show-more__btn', showMoreContainer),
        maxHeight = parseInt(element.getAttribute('data-show-more-max-height')) || 120;

    if (element.offsetHeight > maxHeight) {
        addClass('show-more', showMoreContainer);
        element.style.maxHeight = maxHeight + 'px';
    }

    on('click', (e) => {
        removeClass('show-more', showMoreContainer);
    }, showMoreBtn);
}