import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, addClass, hasClass, find, findIn, findAllIn, closest, on} from '@elements/dom-utils';
import {getPrefixedDataSet} from '@elements/data-set-utils';


export function init () {
    onFind('.js-nav', nav => {
        let toggle = findIn('.js-nav__toggle', nav);
        let overlay = findIn('.js-nav__overlay', nav);
        let links = findAllIn('.js-nav__link', nav);
        let scrollContainerMobile = findIn('.js-nav__container-mobile', nav);
        let level0 = findIn('.js-nav__level-0', nav);
        let level1 = findAllIn('.js-nav__level-1', nav);
        let level2 = findAllIn('.js-nav__level-2', nav);
        let backlinks = findAllIn('.js-nav__back', nav);
        let searchToggles = findAllIn('.js-nav__search', nav);
        let searchOverlay = findIn('.js-nav__search-overlay', nav);

        //toggle overlay
        on('click', (e) => {
            let toggleText = findIn('.js-nav__toggle-text', toggle);
            let dataSet = getPrefixedDataSet('nav-toggle', toggleText);

            if(hasClass('is-active', toggle)){
                removeClass('is-active', overlay);
                removeClass('is-active', toggle);
                removeClass('nav-open', find('body'));
                toggleText.innerText = dataSet.open;
            }else{
                addClass('is-active', overlay);
                addClass('is-active', toggle);
                addClass('nav-open', find('body'));
                toggleText.innerText = dataSet.close;
            }
        }, toggle);

        links.map( link => {
            on('click', (e) => {
                e.preventDefault();
                let item = closest('.js-nav__item', link);

                //parentLink used for resetting
                let parentLink = null;
                if(closest('.js-nav__level-1', item)){
                    let parentItem = closest('.js-nav__item', closest('.js-nav__level-1', item));
                    parentLink = findIn('.js-nav__link', parentItem)
                }

                if(parentLink === link ){
                    parentLink = null;
                }

                //reset all other links except for itself and parentLink
                links.map( otherLink => {
                    if(parentLink !== null){
                        if(otherLink !== link && otherLink !== parentLink){
                            removeClass('is-active', otherLink);
                        }
                    }else{
                        if(otherLink !== link){
                            removeClass('is-active', otherLink);
                        }
                    }
                });

                if(hasClass('is-active', link)) {
                    removeClass('is-active', link);
                }else{
                    addClass('is-active', link);
                    //scroll to top of container
                    if(matchMedia('(min-width: 768px)').matches){
                        overlay.scrollTo(0,0);
                    }else{
                        scrollContainerMobile.scrollTo(0, 0);
                    }


                }

                 if(findIn('.js-nav__level-1', item)){
                     let level = findIn('.js-nav__level-1', item);

                     //reset all level 1
                     level1.map( otherLevel => {
                         if(otherLevel !== level){
                             removeClass('is-active', otherLevel);
                         }
                         level2.map( otherLevel2 => {
                            removeClass('is-active', otherLevel2);
                         });
                     });

                    if(hasClass('is-active', level)) {
                        removeClass('is-active', level);
                    }else{
                        addClass('is-active', level);
                    }
                 }else if(findIn('.js-nav__level-2', item)){
                     let level = findIn('.js-nav__level-2', item);
                     //reset all level 1
                     level2.map( otherLevel => {
                         if(otherLevel !== level){
                             removeClass('is-active', otherLevel);
                         }
                     });

                     if(hasClass('is-active', level)) {
                         removeClass('is-active', level);
                     }else{
                         addClass('is-active', level);
                     }
                 }

                 //if no level 1 or level 2 then add is-active to level 0
                //used for mobile css translate
                //level0 active then class: is-active, if level1 active then no class, if level2 active then class: is-active-2
                let level0Active = true;
                 let level2Active = false;
                level1.map( tempLevel => {
                    if(hasClass('is-active', tempLevel)){
                        level0Active = false;
                    }
                })
                level2.map( tempLevel => {
                    if(hasClass('is-active', tempLevel)){
                        level0Active = false;
                        level2Active = true;
                    }
                })
                 if(level0Active){
                     addClass('is-active', level0);
                     removeClass('is-active-2', level0);
                 }else{
                     removeClass('is-active', level0);
                     if(level2Active){
                            addClass('is-active-2', level0);
                     }else{
                         removeClass('is-active-2', level0);
                     }
                 }
            }, link);
        });

        /*backlinks*/
        backlinks.map( backlink => {
             if(closest('.js-nav__level-2', backlink)){
                 on('click', e => {
                    let level0 = closest('.js-nav__level-0', backlink);
                    let level1 = closest('.js-nav__level-1', backlink);
                    let level2 = closest('.js-nav__level-2', backlink);
                     let tempLinks = findAllIn('.js-nav__link', level1);

                    removeClass('is-active-2', level0);
                    tempLinks.map( tempLink => {
                        removeClass('is-active', tempLink);
                    });
                     //timeout wait for css transition to be finished
                     setTimeout(() => {
                         removeClass('is-active', level2);
                     }, 300);
                 }, backlink);
             }else if(closest('.js-nav__level-1', backlink)){
                on('click', e => {
                    let level0 = closest('.js-nav__level-0', backlink);
                    let level1 = closest('.js-nav__level-1', backlink);
                    let tempLinks = findAllIn('.js-nav__link', level0);

                    addClass('is-active', level0);
                    removeClass('is-active-2', level0);
                    tempLinks.map( tempLink => {
                        removeClass('is-active', tempLink);
                    });
                    //timeout wait for css transition to be finished
                    setTimeout(() => {
                        removeClass('is-active', level1);
                    }, 300);
                }, backlink);
             }
        });

        searchToggles.map( search => {
            on('click', e => {
                addClass('is-active', searchOverlay);
                findIn('.js-nav__search-input', searchOverlay).focus();
                bodyClickHandler(searchOverlay, search);
            }, search);
        });
    });
}

function bodyClickHandler($parent) {
    window.addEventListener("click", function _listener(evt) {
        evt.stopImmediatePropagation();
        if (!$parent.contains(evt.target)) {
            removeClass('is-active', $parent);

            window.removeEventListener("click", _listener, true);
        }
    }, true);
}
