class BookableOptionInstance {
    constructor(
        id,
        type,
        name,
        tooltip,
        singlePrice,
        typeProps = {},
    ) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.tooltip = tooltip;
        this.singlePrice = singlePrice;
        this.calculatedPrice = 0;
        this.typeProps = typeProps;
    }

    calculatePrice(personTypeValues) {
        if (this.type === 'percent') {
            let percentageVal = this.typeProps.percentage,
                totalPrice = 0;

            for (const [key, values] of Object.entries(personTypeValues)) {
                totalPrice += values.totalPrice;
            }

            let calculatedPrice = totalPrice * (percentageVal / 100);
            this.calculatedPrice = calculatedPrice.toFixed(2).replace('.', ',');
            return calculatedPrice;
        }

        if (this.type === 'ticket') {
            let calculatedPrice = parseFloat(this.singlePrice);
            this.calculatedPrice = calculatedPrice.toFixed(2).replace('.', ',');
            return calculatedPrice;
        }

        let totalAmount = 0;
        for (const [key, values] of Object.entries(personTypeValues)) {
            totalAmount += values.amount;
        }

        let calculatedPrice = totalAmount * this.singlePrice;
        this.calculatedPrice = calculatedPrice.toFixed(2).replace('.', ',');
        return calculatedPrice;
    }
}

export { BookableOptionInstance }
